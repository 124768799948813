<template>
  <v-app>
    <v-main>
      <logout class="noprint" />
      <v-container v-if="loading" fill-height grid-list-md text-center ghost>
        <v-row wrap align-center>
          <v-col>
            Computation progress...
            <v-progress-linear
              color="light-blue accent-4"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-container>
      <v-container ghost v-else>
        <v-row>
          <v-col class="text-center">
            <div class="headline text-center"><h3>PEARL-DGS - Results - Second Eyes</h3></div>
          </v-col>
        </v-row>
        <v-row class="justify-space-between">
          <v-col cols="12" sm="3">
            <v-text-field
              label="Patient ID / Name"
              outlined
              class="iol-v-text-field"
              ref="first"
              readonly
              :value="ginputs.patientID"
            />
          </v-col>
          <v-col cols="12" sm="3">
            <v-text-field
              label="Keratometric Index"
              outlined
              class="iol-v-text-field"
              readonly
              :value="ginputs.keratometricIndex"
            />
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              label="Biometer"
              class="iol-v-text-field"
              outlined
              readonly
              :value="ginputs.biometer.text"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-divider />
            <br />
          </v-col>
        </v-row>
        <v-sheet v-if="ginputs.side" class="iol-right-v-sheet">
          <v-row>
            <v-col cols="12" md="6">
              <v-row class="justify-md-space-around">
                <v-col cols="2" md="2">
                  <h2>OD</h2>
                </v-col>
                <v-col cols="3" md="3">
                  AL: {{ ginputs.AL || "--" }} mm <br />
                  K1: {{ ginputs.K1 || "--" }} D <br />
                  K2: {{ ginputs.K2 || "--" }} D <br />
                  ACD: {{ ginputs.ACD || "--" }} mm <br />
                </v-col>
                <v-col cols="4" md="4">
                  A Constant: {{ ginputs.aconst || "--" }} <br />
                  Target refraction: {{ results.target }} <br />
                </v-col>
                <v-col cols="3" md="3">
                  LT: {{ ginputs.LT || "--" }} mm <br />
                  CCT: {{ ginputs.CCT || "--" }} μm <br />
                </v-col>
              </v-row>
              <v-row class="align-baseline">
                <v-col cols="4" md="3">
                  <v-switch
                    label="ICL"
                    hide-details
                    readonly
                    :input-value="ginputs.isICL"
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    label="Corneal refractive surgery"
                    class="iol-v-text-field"
                    outlined
                    readonly
                    :value="ginputs.cornealLaserType.text"
                  />
                </v-col>
              </v-row>
              <v-row class="justify-end">
                <v-col cols="10" md="9">
                  <v-sheet class="iol-opt-v-sheet">
                    <br />
                    <v-text-field
                      label="Pre-LVC anterior corneal radius (mm)"
                      class="iol-v-text-field"
                      outlined
                      readonly
                      :value="ginputs.preLvcArc"
                    />
                    <v-text-field
                      label="Corrected ametropia (D)"
                      class="iol-v-text-field"
                      outlined
                      readonly
                      :value="ginputs.laserAmount"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="6">
              <v-sheet class="iol-v-sheet" outlined>
                <v-row class="justify-space-around">
                  <v-col cols="12" md="10">
                    <h3>Controlateral Operated Eye Information</h3><br />
                  </v-col>
                </v-row>
                <v-row class="justify-space-between">
                  <v-col cols="3" md="3">
                    AL: {{ ginputs.AL2 || "--" }} mm <br />
                    K1: {{ ginputs.K12 || "--" }} D <br />
                    K2: {{ ginputs.K22 || "--" }} D <br />
                    ACD: {{ ginputs.ACD2 || "--" }} mm <br />
                  </v-col>
                  <v-col cols="8" md="6">
                    Implanted A constant: {{ ginputs.aconst2 || "--" }} <br />
                    Postop. spherical equivalent: {{ ginputs.SE2 === null ? '--' : ginputs.SE2 }} D <br />
                    Implanted IOL power: {{ ginputs.power2 === null ? '--' : ginputs.power2 }} D <br />
                  </v-col>
                  <v-col cols="4" md="3">
                    LT: {{ ginputs.LT2 || "--" }} mm <br />
                    CCT: {{ ginputs.CCT2 || "--" }} μm <br />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row class="align-baseline">
                      <v-col cols="4" md="3" offset="1">
                        <v-switch
                          label="ICL"
                          hide-details
                          readonly
                          :input-value="ginputs.isICL2"
                        />
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Corneal refractive surgery"
                          class="iol-v-text-field"
                          outlined
                          readonly
                          :value="ginputs.cornealLaserType2.text"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="justify-end">
                      <v-col cols="10" md="9">
                        <v-sheet class="iol-opt-v-sheet">
                          <br />
                          <v-text-field
                            label="Pre-LVC anterior corneal radius (mm)"
                            class="iol-v-text-field"
                            outlined
                            readonly
                            :value="ginputs.preLvcArc2"
                          />
                          <v-text-field
                            label="Corrected ametropia (D)"
                            class="iol-v-text-field"
                            outlined
                            readonly
                            :value="ginputs.laserAmount2"
                          />
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row class="justify-space-between">
            <v-col cols="12" md="6" sm="3">
              <v-simple-table class="iol-results">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>IOL POWER (D)</th>
                      <th>REFRACTION (D)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ results.pm1.power }}</td>
                      <td>{{ results.pm1.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results.pm05.power }}</td>
                      <td>{{ results.pm05.es }}</td>
                    </tr>
                    <tr>
                      <td class="bg-blue">{{ results.p0.power }}</td>
                      <td class="bg-blue">{{ results.p0.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results.p05.power }}</td>
                      <td>{{ results.p05.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results.p1.power }}</td>
                      <td>{{ results.p1.es }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2">Second Eye Mode</td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="6" sm="3">
              <v-simple-table class="iol-results" style="background-color: #c4c4c4 !important">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>IOL POWER (D)</th>
                      <th>REFRACTION (D)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ results2.pm1.power }}</td>
                      <td>{{ results2.pm1.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results2.pm05.power }}</td>
                      <td>{{ results2.pm05.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results2.p0.power }}</td>
                      <td>{{ results2.p0.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results2.p05.power }}</td>
                      <td>{{ results2.p05.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results2.p1.power }}</td>
                      <td>{{ results2.p1.es }}</td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="2">Without Second Eye Mode - for information</td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-sheet>
        <v-sheet v-if="!ginputs.side" class="iol-left-v-sheet">
          <v-row>
            <v-col cols="12" md="6">
              <v-sheet class="iol-v-sheet" outlined>
                <v-row class="justify-space-around">
                  <v-col cols="12" md="10">
                    <h3>Controlateral Operated Eye Information</h3><br />
                  </v-col>
                </v-row>
                <v-row class="justify-space-between">
                  <v-col cols="3" md="3">
                    AL: {{ ginputs.AL2 || "--" }} mm <br />
                    K1: {{ ginputs.K12 || "--" }} D <br />
                    K2: {{ ginputs.K22 || "--" }} D <br />
                    ACD: {{ ginputs.ACD2 || "--" }} mm <br />
                  </v-col>
                  <v-col cols="8" md="6">
                    Implanted A constant: {{ ginputs.aconst2 || "--" }} <br />
                    Postop. spherical equivalent: {{ ginputs.SE2 === null ? '--' : ginputs.SE2 }} D <br />
                    Implanted IOL power: {{ ginputs.power2 === null ? '--' : ginputs.power2 }} D <br />
                  </v-col>
                  <v-col cols="4" md="3">
                    LT: {{ ginputs.LT2 || "--" }} mm <br />
                    CCT: {{ ginputs.CCT2 || "--" }} μm <br />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-row class="align-baseline">
                      <v-col cols="4" md="3" offset="1">
                        <v-switch
                          label="ICL"
                          hide-details
                          readonly
                          :input-value="ginputs.isICL2"
                        />
                      </v-col>
                      <v-col cols="6" md="6">
                        <v-text-field
                          label="Corneal refractive surgery"
                          class="iol-v-text-field"
                          outlined
                          readonly
                          :value="ginputs.cornealLaserType2.text"
                        />
                      </v-col>
                    </v-row>
                    <v-row class="justify-end">
                      <v-col cols="10" md="9">
                        <v-sheet class="iol-opt-v-sheet">
                          <br />
                          <v-text-field
                            label="Pre-LVC anterior corneal radius (mm)"
                            class="iol-v-text-field"
                            outlined
                            readonly
                            :value="ginputs.preLvcArc2"
                          />
                          <v-text-field
                            label="Corrected ametropia (D)"
                            class="iol-v-text-field"
                            outlined
                            readonly
                            :value="ginputs.laserAmount2"
                          />
                        </v-sheet>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
            </v-col>
            <v-col cols="12" md="6">
              <v-row class="justify-md-space-around">
                <v-col cols="2" md="2">
                  <h2>OS</h2>
                </v-col>
                <v-col cols="3" md="3">
                  AL: {{ ginputs.AL || "--" }} mm <br />
                  K1: {{ ginputs.K1 || "--" }} D <br />
                  K2: {{ ginputs.K2 || "--" }} D <br />
                  ACD: {{ ginputs.ACD || "--" }} mm <br />
                </v-col>
                <v-col cols="4" md="4">
                  A Constant: {{ ginputs.aconst || "--" }} <br />
                  Target refraction: {{ results.target }} <br />
                </v-col>
                <v-col cols="3" md="3">
                  LT: {{ ginputs.LT || "--" }} mm <br />
                  CCT: {{ ginputs.CCT || "--" }} μm <br />
                </v-col>
              </v-row>
              <v-row class="align-baseline">
                <v-col cols="4" md="3">
                  <v-switch
                    label="ICL"
                    hide-details
                    readonly
                    :input-value="ginputs.isICL"
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <v-text-field
                    label="Corneal refractive surgery"
                    class="iol-v-text-field"
                    outlined
                    readonly
                    :value="ginputs.cornealLaserType.text"
                  />
                </v-col>
              </v-row>
              <v-row class="justify-end">
                <v-col cols="10" md="9">
                  <v-sheet class="iol-opt-v-sheet">
                    <br />
                    <v-text-field
                      label="Pre-LVC anterior corneal radius (mm)"
                      class="iol-v-text-field"
                      outlined
                      readonly
                      :value="ginputs.preLvcArc"
                    />
                    <v-text-field
                      label="Corrected ametropia (D)"
                      class="iol-v-text-field"
                      outlined
                      readonly
                      :value="ginputs.laserAmount"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="justify-space-between">
            <v-col cols="12" md="6" sm="3">
              <v-simple-table class="iol-results">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>IOL POWER (D)</th>
                      <th>REFRACTION (D)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ results.pm1.power }}</td>
                      <td>{{ results.pm1.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results.pm05.power }}</td>
                      <td>{{ results.pm05.es }}</td>
                    </tr>
                    <tr>
                      <td class="bg-blue">{{ results.p0.power }}</td>
                      <td class="bg-blue">{{ results.p0.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results.p05.power }}</td>
                      <td>{{ results.p05.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results.p1.power }}</td>
                      <td>{{ results.p1.es }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="12" md="6" sm="3">
              <v-simple-table class="iol-results">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>IOL POWER (D)(second false)</th>
                      <th>REFRACTION (D)(second false)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{ results2.pm1.power }}</td>
                      <td>{{ results2.pm1.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results2.pm05.power }}</td>
                      <td>{{ results2.pm05.es }}</td>
                    </tr>
                    <tr>
                      <td class="bg-blue">{{ results2.p0.power }}</td>
                      <td class="bg-blue">{{ results2.p0.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results2.p05.power }}</td>
                      <td>{{ results2.p05.es }}</td>
                    </tr>
                    <tr>
                      <td>{{ results2.p1.power }}</td>
                      <td>{{ results2.p1.es }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-sheet>
        <div class="noprint">
          <v-row>
            <v-col cols="12" class="text-center gray">
              <a @click="print" :style="resetstyle">Print</a>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn to="/second" color="#3B4256" class="white--text" x-large
                >Modify Calculation</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

import Logout from '@/components/Logout.vue'

export default {
  name: 'Result',
  components: {
    Logout
  },
  data () {
    return {
      resetstyle: { color: '#838383' },
      loading: true
    }
  },
  mounted () {
    setTimeout(() => {
      this.loading = false
    }, 2000)
  },
  computed: {
    ...mapState(['results', 'results2', 'ginputs', 'ginputs2', 'appHeader'])
  },
  methods: {
    print () {
      window.print()
    }
  }
}
</script>
